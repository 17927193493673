import React from "react";

const LandingPE = () => {
  return (
    <div className="col-12 col-lg-6 col-md-6 m-auto px-0 text-center">
      <div className="card">
        <div className="card-body">
          <img src="/assets/logo-pe.png" alt="logo-france-travail" />
          <p className="mt-3">
            Votre questionnaire est maintenant terminé. Un courrier concernant
            votre prochain RDV va vous être adressée. <br />
            <strong>
              Vous serez reçu(e) par un conseiller France Travail et poserez
              ensemble le cadre de votre accompagnement vers l'emploi
            </strong>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingPE;
