import React from "react";

const LandingCD = () => {
  return (
    <div className="col-12 col-lg-6 col-md-6 m-auto px-0 text-center">
      <div className="card">
        <div className="card-body">
          <img src="/assets/logo-cd.png" />
          <p className="mt-3">
            Votre questionnaire est maintenant terminé et votre inscription à
            France Travail est finalisée. <br />
            <strong>
              Vous serez prochainement contacté(e) par un agent du Conseil
              Départemental afin de vous proposer un rendez-vous avec le
              professionnel qui vous accompagnera dans vos démarches
              personnelles et professionnelles
            </strong>
            . <br />
            Ce rendez-vous est obligatoire.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingCD;
