import React from "react";

const LandingML = ({ url }) => {
  return (
    <div className="col-12 col-lg-6 col-md-6 m-auto px-0 text-center">
      <div className="card">
        <div className="card-body">
          <i className="far fa-check-circle text-success fa-3x mb-3 animated  rotateIn delay-1s" />
          <p className="">
            Votre questionnaire est maintenant terminé. <br />
            <strong>
              Vous serez prochainement contacté(e) par un agent de la Mission
              Locale
            </strong>{" "}
            afin de prendre rendez-vous avec le professionnel qui vous
            accompagnera dans vos démarches. La Mission Locale est spécialisée
            dans l'accompagnement des jeunes. <br />
            <strong>Ce rendez-vous est obligatoire</strong>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingML;
